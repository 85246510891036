import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';
let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class NoteService {
    async addNote(data) {
        try {
            const res = await axios.post(API_URL + 'jobs/note', data,
        { 
            headers: AuthService.authHeader()
        });
        //console.log(res.data)
        return res.data;
        }
        catch (err) {
            console.log(err)
            return err;
        }
        
    }

    async getAllNotesJobWise(id) {
        try {
            const res = await axios.get(API_URL + 'jobs/note/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

    async updateNote(id) {
        try {
            const res = await axios.put(API_URL + 'jobs/note/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

    async deleteNote(id) {
        try {
            const res = await axios.delete(API_URL + 'jobs/note/' + id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err)
            return err
        }
    }

}

export default new NoteService();